import React, { PropsWithChildren } from 'react';
import s from './PardusFooter.module.css';
import Footer from 'rc-footer';
import { Container } from 'react-bootstrap';

export const WithPardusFooter = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div className={s.container}>
      {children}
      <div className={s.footer}>
        <Container>
          <Footer
            className={s.footerContainer}
            backgroundColor={'#130a2b'}
            columnLayout={'space-between'}
            columns={[
              {
                title: 'pardus.is',
                icon: <img src="/img/logo.png" />,
                items: [
                  {
                    title: 'Hvað er Pardus?',
                    url: 'http://info.pardus.is',
                    openExternal: true,
                  },
                  { title: 'Skilmálar', url: '/privacy' },
                ],
                className: s.footerColumn,
              },
              {
                title: 'Fyrir höfunda',
                items: [
                  {
                    title: 'Skrá mig',
                    url: 'https://info.pardus.is/register',
                    openExternal: true,
                  },
                ],
                className: s.footerColumn,
              },
              {
                title: 'Hafa samband',
                items: [
                  { title: 'pardus@pardus.is', url: 'mailto:pardus@pardus.is' },
                  { title: 'kt. 411020-1180' },
                ],
                className: s.footerColumn,
              },
            ]}
          />
        </Container>
      </div>
    </div>
  );
};
