import { useEffect, useMemo, useState } from 'react';

export function usePostToken(externalLoginToken?: string) {
  const [tokenIsPosted, setTokenIsPosted] = useState(false);
  const allowedOriginList = useMemo(
    () => [
      'http://localhost:4200',
      'http://127.0.0.1:4200',
      'https://livestream-trpc.vercel.app',
      'https://trivio.is',
      'https://app.trivio.is',
      'https://dev.trivio.is',
    ],
    [],
  );
  useEffect(() => {
    if (tokenIsPosted) {
      return;
    }
    if (externalLoginToken) {
      allowedOriginList.forEach((origin) => {
        try {
          window.opener.postMessage({ externalLoginToken }, origin);
        } catch (e) {}
      });
      setTokenIsPosted(true);
    }
  }, [allowedOriginList, externalLoginToken, tokenIsPosted]);
}
