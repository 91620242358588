import React, { useEffect, useState } from 'react';
import s from './TopNav.module.css';
import { Container } from 'react-bootstrap';
import { Link, NavLink, Redirect, useHistory } from 'react-router-dom';
import { LinkItem } from '../../types';
import { useFirebase } from 'react-redux-firebase';
import { useTypedSelector } from '../../redux/reducers';
import classNames from 'classnames';
import clientConfig from '../../client-config';
export const TopNav: React.FC<{ transparent?: boolean }> = ({
  transparent,
}) => {
  const firebase = useFirebase();
  const [redirect, setRedirect] = useState('');
  const auth = useTypedSelector((state) => state.firebase.auth);
  const profile = useTypedSelector((state) => state.firebase.profile);
  const logout = async () => {
    await firebase.logout();
    window.location.reload();
  };
  const generalLinks: LinkItem[] = [{ to: '/', title: 'Heim' }];
  const loggedInLinks: LinkItem[] = [
    ...generalLinks,
    { to: '/subscriptions', title: 'Mínar áskriftir' },
    { to: '/rss', title: 'RSS hlekkir' },
  ];
  const loggedOutLinks: LinkItem[] = [
    ...generalLinks,
    {
      onClick: () => window.open('http://info.pardus.is'),
      title: 'Hvað er Pardus?',
    },
    { to: '/login', title: 'Innskráning' },
  ];
  let links: LinkItem[] = [];
  if (auth.isLoaded && auth.isEmpty) {
    links = loggedOutLinks;
  }
  if (auth.isLoaded && !auth.isEmpty) {
    links = loggedInLinks;
    if (profile?.isAdmin) {
      links.push({ to: '/registeredusers', title: 'Notendur' });
    }
    links.push({
      onClick: () => logout(),
      title: `Útskráning (${auth.email})`,
    });
  }
  if (clientConfig.name === 'visir') {
    return null;
  }
  return (
    <>
      <div
        className={classNames({
          [s.container]: true,
          [s.transparent]: transparent,
        })}
      >
        <Container>
          <div className={s.content}>
            <div className={s.logo} onClick={() => setRedirect('/')}>
              <img src={'/img/logo.png'} alt={'Pardus'} />
              <div className={s.pardus}>Pardus</div>
            </div>
            <div className={s.menu}>
              <MenuLinks links={links} />
            </div>
            <MobileMenu links={links} />
          </div>
        </Container>
      </div>
      {redirect && <Redirect to={redirect} />}
    </>
  );
};

const MenuLinks: React.FC<{ links: LinkItem[] }> = ({ links }) => {
  return (
    <>
      {links.map((l, index) =>
        l.onClick ? (
          <a href={'#'} key={index} onClick={l.onClick}>
            {l.title}
          </a>
        ) : (
          <NavLink
            exact
            activeClassName={s.active}
            key={l.to}
            to={l.to as string}
          >
            {l.title}
          </NavLink>
        )
      )}
    </>
  );
};

const MobileMenu: React.FC<{ links: LinkItem[] }> = ({ links }) => {
  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    if (visible) {
      document.documentElement.classList.add('with-modal');
    } else {
      document.documentElement.classList.remove('with-modal');
    }
    return () => {
      document.documentElement.classList.remove('with-modal');
    };
  }, [visible]);
  return (
    <div className={s.mobileMenu}>
      {!visible && (
        <div className={s.menuIcon} onClick={() => setVisible(true)} />
      )}
      {visible && (
        <div className={s.closeIcon} onClick={() => setVisible(false)} />
      )}
      {visible && (
        <div className={s.mobileMenuContent}>
          <MenuLinks links={links} />
        </div>
      )}
    </div>
  );
};
